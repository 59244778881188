import React, { useState, useRef, useEffect } from "react";

// Styles.
import * as styles from "./accordion.module.scss";

export default function Accordion({ title, open = false, className = '', children }) {
  const [isActive, setIsActive] = useState(false);
  const [height, setHeight] = useState("0px");
  const accordionContentRef = useRef(null);

  useEffect(() => {
    if (open) {
      setIsActive(true);
      setHeight(`${accordionContentRef.current.scrollHeight}px`);
    }
  }, [open, setIsActive]);

  const toggleAccordion = () => {
    setIsActive(!isActive);
    setHeight(
      isActive ? "0px" : `${accordionContentRef.current.scrollHeight}px`
    );
  };

  return (
    <div
      className={`${styles.accordion} ${className} ${
        isActive ? "active" : "not-active"
      } `}
    >
      <div className={styles.accordionHeader}>
        <button
          className={`${styles.accordionButton} ${
            isActive ? styles.accordionButtonActive : "not-active"
          }`}
          onClick={toggleAccordion}
        >
          {title}
        </button>
      </div>
      <div
        className={`${styles.accordionContent}`}
        ref={accordionContentRef}
        style={{ height: `${height}` }}
      >
        {children}
      </div>
    </div>
  );
}
