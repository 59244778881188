import React, { useEffect } from "react";
import { Link, useTranslation } from "gatsby-plugin-react-i18next";
import useAxios from "axios-hooks";
import * as styles from "./search-cards.module.scss";

export const SearchCardDefault = ({ fetchNodeUrl }) => {
  const { t } = useTranslation();
  // Contains all the data returns from the API.
  const [{ data: nodeData }] =
    useAxios(fetchNodeUrl);

  // Get Node ArticleTag
  const [{ data: nodeArticleTypeTag }] = useAxios(
    nodeData?.data?.relationships?.field_article_type?.links?.related?.href
  );

  // Get Node Article Author
  const [{ data: nodeArticleAuthor }] = useAxios(
    nodeData?.data?.relationships?.field_author?.links?.related?.href
  );

  // Get Node Article Tags
  const [{ data: nodeArticleTags }] = useAxios(
    nodeData?.data?.relationships?.field_tags?.links?.related?.href
  );

  useEffect(() => {
    let fetchingData = false;
    // If not fetching data go get it.
    if (!fetchingData) {
      // refetch();
    }

    return () => {
      fetchingData = true;
    };
  }, []);

  // Format article published date to be: Month Name Day, Year
  const formatePublishedDate = (date) => {
    return new Date(date).toLocaleDateString("en-us", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  return (
    <>
      {nodeData?.data?.attributes && (
        <article className={`${styles.card}`}>
          <section className={`${styles.cardInner}`}>
            {/* Title and path */}
            {nodeData?.data?.attributes?.title &&
              nodeData?.data?.attributes?.path?.alias && (
                <div className={`${styles.cardTitle}`}>
                  <Link to={nodeData.data.attributes?.path?.alias}>
                    {nodeData.data.attributes?.title}
                  </Link>
                </div>
              )}

            {/* Get article tag */}
            {nodeData?.data?.relationships?.field_article_type?.links?.related
              ?.href &&
              nodeArticleTypeTag?.data?.attributes?.name && (
                <div className={`${styles.cardArticleType}`}>
                  {nodeArticleTypeTag.data.attributes.name}
                </div>
              )}

            <div>
              {/* Article Author */}
              {nodeData?.data?.relationships?.field_author?.links?.related
                ?.href &&
                nodeArticleAuthor && (
                  <div className={`${styles.cardAuthor}`}>
                    {t("search.card.author", "Author")}: {""}
                    <Link
                      to={nodeArticleAuthor?.data[0]?.attributes?.path?.alias}
                    >
                      {nodeArticleAuthor?.data[0]?.attributes?.title}
                    </Link>
                  </div>
                )}

              {/* Article Tags */}
              {nodeData?.data?.relationships?.field_tags?.links?.related
                ?.href &&
                nodeArticleTags &&
                nodeArticleTags?.data &&
                nodeArticleTags.data.length >= 1 && (
                  <div className={`${styles.cardArticleTags}`}>
                    <span className={`${styles.cardArticleTagsTitle}`}>
                      {t("search.card.category", "Category")}:
                    </span>
                    {nodeArticleTags?.data &&
                      nodeArticleTags.data.length >= 1 &&
                      nodeArticleTags.data.map((tag, i) => (
                        <div key={i}>
                          {tag?.attributes?.name && (
                            <span className={`${styles.cardArticleTag}`}>
                              {tag.attributes.name}
                            </span>
                          )}
                        </div>
                      ))}
                  </div>
                )}

              {/* Article Date */}
              {nodeData?.data?.attributes?.field_pub_date_mises_org && (
                <div className={`${styles.card__date}`}>
                  {t("search.card.onlinePublishedDate", "Online Publish Date")}:{" "}
                  {formatePublishedDate(
                    nodeData.data.attributes.field_pub_date_mises_org
                  )}
                </div>
              )}
            </div>

            {/* Article Teaser Text */}
            {nodeData.data.attributes.field_teaser && (
              <div
                className={`${styles.card__teaser_text}`}
                dangerouslySetInnerHTML={{
                  __html: nodeData.data.attributes.field_teaser.value,
                }}
              />
            )}
          </section>
        </article>
      )}
    </>
  );
}
