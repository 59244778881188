import React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
// Styles.
import * as styles from "./pagination.module.scss";

export default function SearchPagination({ refetch, searchData }) {
  const { t } = useTranslation();
  const prevNextLastClick = (fetchUrl) => {
    if (!fetchUrl) return;
    refetch({ url: fetchUrl });
  };

  return (
    <div className={`${styles.SearchPagination}`}>
      {searchData?.links?.prev?.href && (
        <button
          className={`${styles.SearchPaginationButton} ${styles.SearchPaginationButtonBack}`}
          onClick={(e) => {
            e.preventDefault();
            prevNextLastClick(searchData.links.prev.href);
          }}
        >
          <svg
            aria-hidden="true"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
              clipRule="evenodd"
            ></path>
          </svg>
          {t("search.pagination.previous", "Previous")}
        </button>
      )}
      {searchData?.links?.next?.href && (
        <button
          className={`${styles.SearchPaginationButton} ${styles.SearchPaginationButtonForward}`}
          onClick={(e) => {
            e.preventDefault();
            prevNextLastClick(searchData.links.next.href);
          }}
        >
          {t("search.pagination.next", "Next")}
          <svg
            aria-hidden="true"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
              clipRule="evenodd"
            ></path>
          </svg>
        </button>
      )}
    </div>
  );
}
