import React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";

// Styles.
import * as styles from "./search-summary.module.scss";

export const SearchSummary = ({ searchData, searchInputValue }) => {
  const { t } = useTranslation();
  // const [facetTerms, setFacetTerms] = useState([]);

  // useEffect(() => {
  //   if (searchData?.meta?.facets) {

  //     const filteredActiveFacets = searchData.meta.facets
  //       .filter((element) =>
  //         element.terms.some((subTerm) => subTerm?.values?.active)
  //       )
  //       .map(facets => {
  //         return facets.terms
  //       })
  //     // TODO: Working on getting active facets in a single array of objects
  //     // console.log(filteredActiveFacets);
  //     setFacetTerms(filteredActiveFacets);
  //   }
  // }, [searchData]);
  return (
    <div className={`${styles.SearchSummary}`}>
      <SearchIntroTextBlock />
      {searchInputValue && (
        <div className={`${styles.SearchSummaryCurrentSearch}`}>
          <span>
            <b>{searchData?.meta?.count} </b>
            {t("search.searchResultsFound", "results found for")}
          </span>
          <span> "{searchInputValue}"</span>
        </div>
      )}
    </div>
  );
};

export const SearchIntroTextBlock = () => {
  const { t } = useTranslation();
  return (
    <div className={`${styles.SearchIntroTextBlock}`}>
      <div className={`${styles.SearchIntroTextBlockTitle}`}>
        {t("search.searchWelcomeHeadline", "Welcome to our search page.")}
      </div>
      <div
        className={styles.sectionDescription}
        dangerouslySetInnerHTML={{
          __html: t(
            "search.searchWelcomeMessage",
            "<p>As you use the search throughout the site, there are a few things to keep in mind: </p><ul> <li> You can filter results by date, author, topic and other attributes on the left </li><li>To save a search using the bookmarking feature in your browser</li>"
          ),
        }}
      ></div>
    </div>
  );
}
