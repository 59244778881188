// Search bar found on /search page.
import React, { useEffect, useState } from "react";
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next";

// Styles.
import * as styles from "./search-form.module.scss";

export const SearchForm = ({
  urlParams,
  searchAPIParamsObject,
  setSearchAPIParamsObject,
  searchInputValue,
  setSearchInputValue,
  pageLimit,
  setPageLimit,
}) => {
  const { language } = useI18next();
  const [sortValues, setSortValues] = useState({
    sortBy: "search_api_relevance",
    orderBy: "-",
  });

  const { t } = useTranslation();

  useEffect(() => {
    // Set the Search input value if filter[fulltext] is in the
    if (urlParams) {
      // Set search input value if the url params match.
      if (urlParams.has("filter[fulltext]")) {
        setSearchInputValue(urlParams.get("filter[fulltext]"));
      }
      // Set sort.
      if (urlParams.has("sort")) {
        const firstCharofSort = Array.from(urlParams.get("sort"))[0];

        if (firstCharofSort !== "-") {
          setSortValues((sortValues) => ({
            sortBy: urlParams.get("sort"),
            orderBy: "",
          }));
        } else {
          setSortValues((sortValues) => ({
            sortBy: urlParams.get("sort").substring(1),
            orderBy: "-",
          }));
        }
      }
      // Page Limit AKA results per page in url
      if (urlParams.has("page[limit]")) {
        setPageLimit(urlParams.get("page[limit]"));
      }
    }
  }, [urlParams, setSearchInputValue, setPageLimit]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const temporySearchAPIParamsObject = searchAPIParamsObject;
    // Filters
    for (const filter of ["fulltext"]) {
      if (event.target[filter]?.value !== "") {
        temporySearchAPIParamsObject["filter"][filter] =
          event.target[filter].value;
        setSearchInputValue(event.target[filter].value);
      } else {
        setSearchInputValue(null);
        delete temporySearchAPIParamsObject["filter"][filter];
      }
    }

    // Sort needs to be comma separted for JSON API if you need more than one type sort.
    // Example for sorting by field_pub_date_mises_org DESC and uid ASC : sort: "-field_pub_date_mises_org,uid"
    // Minus equals Descending none for ASC

    // Get Sort by
    if (
      event.target["sortBy"]?.value &&
      event.target["sortBy"].value !== sortValues.sortBy
    ) {
      setSortValues((sortValues) => ({
        ...sortValues,
        sortBy: event.target["sortBy"].value,
      }));
    }

    // Get Desc or ASC
    if (!event.target["orderBy"]?.value) {
      // ASC
      setSortValues((sortValues) => ({
        ...sortValues,
        orderBy: "",
      }));
    } else {
      // DESC
      setSortValues((sortValues) => ({
        ...sortValues,
        orderBy: "-",
      }));
    }

    // Results per Page.
    if (event.target["pageLimit"]?.value) {
      temporySearchAPIParamsObject["page"]["limit"] =
        event.target["pageLimit"].value;
    }

    // Sort Order
    temporySearchAPIParamsObject[
      "sort"
    ] = `${sortValues.orderBy}${sortValues.sortBy}`;

    setSearchAPIParamsObject({ ...temporySearchAPIParamsObject });
  };

  return (
    <form className={`${styles.SearchForm}`} onSubmit={handleSubmit}>
      <div className={`${styles.SearchFormFieldWrapper}`}>
        <label className={`${styles.SearchFormTitle}`} htmlFor="fulltext">
          {t("search.form.inputLabel", "Search")}
        </label>
        <input
          id="fulltext"
          className={`${styles.SearchFormTextinput}`}
          name="fulltext"
          placeholder={t("search.form.inputPlaceholder", "Search")}
          type="text"
          defaultValue={searchInputValue || ""}
        />
      </div>

      <div className={`${styles.SearchFormFieldGrid}`}>
        {/* Sort By */}
        <div className={`${styles.SearchFormFieldWrapper}`}>
          <label htmlFor="sortBy" className={`${styles.SearchFormLabel}`}>
            {t("search.form.sortByLabel", "Sort By")}
          </label>
          <select
            id="sortBy"
            className={`${styles.SearchFormSelect}`}
            name="sortBy"
            value={sortValues.sortBy}
            onChange={(event) =>
              setSortValues((sortValues) => ({
                ...sortValues,
                sortBy: event.target.value,
              }))
            }
          >
            <option value="search_api_relevance">
              {t("search.form.sortByRelevance", "Relevance")}
            </option>
            <option value="field_pub_date_mises_org">
              {t("search.form.sortByDate", "Date")}
            </option>
          </select>
        </div>
        {/* Order Desending or Asecending. */}
        <div className={`${styles.SearchFormFieldWrapper}`}>
          <label htmlFor="orderBy" className={`${styles.SearchFormLabel}`}>
            {t("search.form.orderLabel", "Order")}
          </label>
          <select
            id="orderBy"
            className={`${styles.SearchFormSelect}`}
            name="orderBy"
            value={sortValues.orderBy || ""}
            onChange={(event) =>
              setSortValues((sortValues) => ({
                ...sortValues,
                orderBy: event.target.value,
              }))
            }
          >
            <option value="-">
              {t("search.form.orderByDescending", "Descending")}
            </option>
            <option value="">
              {t("search.form.orderByAscending", "Ascending")}
            </option>
          </select>
        </div>
        <div className={`${styles.SearchFormFieldWrapper}`}>
          <label htmlFor="pageLimit" className={`${styles.SearchFormLabel}`}>
            {t("search.form.resultsPerPage", "Results Per Page")}
          </label>
          <select
            id="pageLimit"
            className={`${styles.SearchFormSelect}`}
            name="pageLimit"
            value={pageLimit || ""}
            onChange={(event) => setPageLimit(event.target.value)}
          >
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </div>
      </div>

      <div className={`${styles.SearchFormActions}`}>
        <button type="submit" className={`${styles.SearchFormSubmit}`}>
          {t("search.form.submitBtn", "Apply")}
        </button>
        {/* TODO: this could probably be better and just clear the search api fitlers. Do assume the primary lang is en. */}
        <a
          className={`${styles.SearchFormClear}`}
          href={language === "en" ? "/search" : `/${language}/search`}
        >
          {t("search.form.clearBtn", "Clear")}
        </a>
      </div>
    </form>
  );
};
