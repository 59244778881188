import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import useAxios from "axios-hooks";
import { DrupalJsonApiParams } from "drupal-jsonapi-params";
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next";

// Layout.
import Global from "../../layouts/global/global";

// Components.
import { SearchForm } from "../../components/search/search-form/search-form";
import { Facets } from '../../components/search/facets/facets';
import { SearchResults } from "../../components/search/search-results/search-results";
import ArticleHeader from "../../components/blocks/article-header/article-header"
import { LoadingArea } from "../../components/loading/loading";
import { SearchSummary } from "../../components/search/search-summary/search-summary";
import  SearchPagination from "../../components/search/pagination/pagination";

// Helper.
import { santizeQueryParameters } from "../../helpers/helpers";

// Styles.
import * as styles from "./search.module.scss";



// Variable to check if its the first render.
let hasLoaded = false;
// Get current url and url params on load.
const url = typeof window !== "undefined" ? new URL(window.location.href) : "";
const urlParams = typeof window !== "undefined" ? new URLSearchParams(url.search) : "";
const acceptedURLParams = [
  'filter[status]',
  'filter[langcode]',
  'filter[fulltext]',
  'filter[article_type]',
  'filter[austrian_school]',
  'filter[medium]',
  'page[limit]',
  'filter[year_published]',
  'sort'
];

export default function Search() {
  const { language } = useI18next();
  const { t } = useTranslation();
  // Base API URL.
  let APIURL = `https://dev-mises-api.pantheonsite.io/api/index/mises_content`;
  if (language !== 'en') {
    APIURL = `https://dev-mises-api.pantheonsite.io/${language}/api/index/mises_content`;
  }

  const initialApiParams = new DrupalJsonApiParams();
  // PageLimit.
  const [pageLimit, setPageLimit] = useState(5);
  // Create inital params to pass to API.
  initialApiParams
    // Add Filters.
    .addFilter("status", "1") // Only get published
    .addFilter("langcode", language) // Filter by lang en or es or whatever the users current select language is
    .addPageLimit(pageLimit)
    // Add Sort
    .addSort("field_pub_date_mises_org", "DESC");
  // Set Search API query params.
  const [searchAPIParamsObject, setSearchAPIParamsObject] = useState({
    ...initialApiParams.getQueryObject(),
  });

  // Data is search API Query.
  const [{ data, loading, error }, refetch] = useAxios(APIURL);

  // Search input Value.
  const [searchInputValue, setSearchInputValue] = useState();

  useEffect(() => {
    let fetchingData = false;
    // If not fetching data go get it.
    if (!fetchingData) {
      // Build out new JSONAPI Params.
      const apiParams = new DrupalJsonApiParams();
      apiParams.initialize(searchAPIParamsObject);
      const queryString = apiParams.getQueryString({ encode: false });
      // Build out API URL.
      let searchAPIUrl = APIURL;
      // First render and url has url params.
      if (!hasLoaded && urlParams.toString()) {
        // TODO: Might need to compage queryString to urlParams and merge and or replace
        // Check if search header query exist and append.
        if (urlParams.has("query")) {
          urlParams.append("filter[fulltext]", urlParams.get("query"));
          // Set search input.
          setSearchInputValue(urlParams.get("query"));
          urlParams.delete("query");
        }
        const filtersQueryParameters = santizeQueryParameters(
          acceptedURLParams,
          urlParams
        );
        searchAPIUrl = `${APIURL}?${filtersQueryParameters.filteredParams.toString()}`;
      }
      // First time loading and with no Query params.
      else if (!hasLoaded) {
        searchAPIUrl = `${APIURL}?${queryString}`;
      } else {
        searchAPIUrl = `${APIURL}?${queryString}`;
        // Apppend queryString to url
        const url = `${window.location.pathname}?${queryString}`;
        window.history.pushState({}, "", decodeURI(url));
      }
      refetch({ url: searchAPIUrl });
    }

    return () => {
      hasLoaded = true;
      fetchingData = true;
    };
  }, [searchAPIParamsObject, APIURL, refetch]);

  return (
    <Global>
      <div className={`${styles.searchPage}`}>
        <ArticleHeader
          iconType={"library"} // TODO: Icon Type may need to be changed after adjusting article header type
          sectionName={"MISES SEARCH"}
          showNewsletter={false}
          isHeader={true}
        />
        <div className={`${styles.searchPageInner}`}>
          {/* Side of filters here */}
          <aside className={`${styles.searchPageSideBar}`}>
            {/* Search Form */}
            <SearchForm
              urlParams={urlParams}
              searchAPIParamsObject={searchAPIParamsObject}
              setSearchAPIParamsObject={setSearchAPIParamsObject}
              searchInputValue={searchInputValue}
              setSearchInputValue={setSearchInputValue}
              pageLimit={pageLimit}
              setPageLimit={setPageLimit}
            />
            {/* Facets */}
            {loading ? (
              <LoadingArea />
            ) : (
              <Facets
                searchAPIParamsObject={searchAPIParamsObject}
                setSearchAPIParamsObject={setSearchAPIParamsObject}
                searchResults={data}
              />
            )}
          </aside>

          <div className={`${styles.searchPageMainArea}`}>
            {/* Search Summary */}
            <SearchSummary
              searchData={data}
              searchInputValue={searchInputValue}
            />
            {/* Search results. */}
            {loading ? (
              <LoadingArea />
            ) : (
              <>
                <SearchResults results={data} />
                <SearchPagination refetch={refetch} searchData={data} />
              </>
            )}
            {error && (
              <div>
                {`${t(
                  "search.errorMessage",
                  "There is a problem fetching the post data"
                )} - ${error}`}
              </div>
            )}
          </div>
        </div>
      </div>
    </Global>
  );
}

export const data = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
