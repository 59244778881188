/**
 * Returns filteredParams and the original urlParams
 * filteredParams.toString() returns filtered params.
 */
export function santizeQueryParameters(
  acceptedURLParams = [],
  urlParams = new URLSearchParams()
) {
  const filteredParams = new URLSearchParams();
  for (const key of acceptedURLParams) {
    if (urlParams.has(key)) {
      filteredParams.append(key, urlParams.get(key));
    }
  }
  return { filteredParams, urlParams };
}
