import React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";

// Styles.
import * as styles from "./loading.module.scss";


export const FullPageLoading = ({title}) => {
  const { t } = useTranslation();

  return (
    <div className={`${styles.fullPageLoading}`}>
      <div className={`${styles.fullPageLoadingLoader}`}>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      </div>
      <div className={`${styles.fullPageLoadingTitle}`}>
        {title ? title : t("loading.text", "Loading...")}
      </div>
    </div>
  );
};

export const LoadingArea = ({ title }) => {
  const { t } = useTranslation();
  return (
    <div className={`${styles.LoadingArea}`}>
      <div className={`${styles.LoadingAreaLoader}`}>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      </div>
      <div className={`${styles.LoadingAreaTitle}`}>
        {title ? title : t("loading.text", "Loading...")}
      </div>
    </div>
  );
};
