import React from "react";
import PropTypes from 'prop-types';
import * as styles from "./article-header.module.scss";
import { Link } from "gatsby"
import {useTranslation, Trans} from 'gatsby-plugin-react-i18next';
import iconWire from "../../../images/icons/icon-shield-wire.svg"
import iconAudio from "../../../images/icons/icon-shield-audio.svg"
import iconEvent from "../../../images/icons/icon-shield-event.svg"
import iconLibrary from "../../../images/icons/icon-shield-library.svg"
import iconPM from "../../../images/icons/icon-shield-powermarket.svg"
import iconItunes from "../../../images/icons/icon-itunes.svg";
import iconRss from "../../../images/icons/icon-rss.svg";
import iconEmail from "../../../images/icons/icon-envelope-solid-white.svg";

ArticleHeader.propTypes = {
    iconType: PropTypes.number,
    sectionName: PropTypes.string,
    showNewsletter: PropTypes.bool,
    isHeader: PropTypes.bool
};

export default function ArticleHeader({iconType, sectionName, showNewsletter = true, isHeader = false}) {
    const { t } = useTranslation();

    // Map tids to icons
    let imagePath = null;
    if (iconType === 1 || iconType === "wire" ) {
        imagePath = iconWire;
    }
    if (iconType === "podcast") {
      imagePath = iconAudio;
    }
    if (iconType === "library") {
      imagePath = iconLibrary;
    }
    let newsletter = null;
    if (showNewsletter) {
        newsletter = [<div className={styles.printShare}>
            <div className={styles.newsletter}>
                <Link to={'/about-mises/subscribe'} className={styles.newsletterLink}>
                    <span className={styles.newsLetterIcon}><img className={styles.iconImage} src={iconEmail} /></span>
                    {t('article-header.newsletter', 'Get news and articles in your inbox')}
                </Link>
            </div>
            <a><code>@TODO</code> print button</a>
            <a><code>@TODO</code> text smaller</a>
            <a><code>@TODO</code> text bigger</a>
        </div>];
    }

    let badgeMarkup = [
        <p className={styles.type}>
            <span className={styles.icon}><img src={imagePath} /></span>
            {sectionName}
        </p>
    ]
    if (isHeader) {
        badgeMarkup = [
            <h1 className={styles.type}>
                <span className={styles.icon}><img src={imagePath} /></span>
                {sectionName}
            </h1>
        ]
    }





    return (
        <section className={styles.header}>
            {badgeMarkup}
            {newsletter}
        </section>
    )
}
