// Facets found on /search
import React, { useState, useEffect } from "react";

import Accordion from "../../accordion/accordion";

// Styles.
import * as styles from "./facets.module.scss";

export const Facets = ({
  searchAPIParamsObject,
  setSearchAPIParamsObject,
  searchResults,
}) => {
  // Facets
  const [facets, setFacets] = useState(null);

  useEffect(() => {
    let fetchingFacetsData = false;
    // Get Facets
    const getMetaFacets = () => {
      // Check if facets results count is > than zero and facets array exist.
      if (searchResults?.meta?.count > 0 && searchResults?.meta?.facets) {
        setFacets(searchResults?.meta?.facets);
      } else {
        setFacets(null);
      }
    };
    if (!fetchingFacetsData) {
      getMetaFacets();
    }
    return () => {
      fetchingFacetsData = true;
    };
  }, [searchResults, facets]);


  const handleFacetClick = (facetGroup, facet) => {
    const temporySearchAPIParamsObject = searchAPIParamsObject;
    if (!facet.values.active) {
      temporySearchAPIParamsObject["filter"][facetGroup.path] = facet.values.value
      setSearchAPIParamsObject({ ...temporySearchAPIParamsObject });
    } else {
      delete temporySearchAPIParamsObject["filter"][facetGroup.path];
      setSearchAPIParamsObject({ ...temporySearchAPIParamsObject });
    }
  };

  const anyFacetsActiveInGroup = (facetGroup) => {
    if (facetGroup && facetGroup.terms.length >= 1) {
      return facetGroup.terms.some(function (arrVal) {
        return arrVal.values.active === true
      });
    }
    return false;
  }


  return (
    <>
      {facets &&
        facets.map((facetGroup, indexOne) => (
          <div
            key={indexOne}
            className={`facet-group facet-group--${facetGroup.path}`}
          >
            {/* Only render facet if terms array is >= 1 */}
            {facetGroup.terms.length >= 1 &&
              <Accordion
                title={facetGroup.label}
                open={indexOne === 0 ? true : anyFacetsActiveInGroup(facetGroup)}
                className={styles.facetAccordion}
              >
                {/* Loop over facets in a group. */}
                <div className={`facet-group__items`}>
                  {facetGroup.terms.map((facet, indexTwo) => (
                      <div
                        className={`${styles.formCheck} facetChild`}
                        key={indexTwo}
                      >
                        <label className={`${styles.formCheckLabel}`}>
                          <input
                            className={`${styles.formCheckInput}`}
                            type="checkbox"
                            checked={facet.values.active ? true : false}
                            onChange={(e) => {
                              handleFacetClick(facetGroup, facet);
                            }}
                          />
                          <span className={`${styles.formCheckLabelText}`}>
                            {facet?.values?.label} ({facet?.values?.count})
                          </span>
                        </label>
                      </div>
                    ))}
                </div>
              </Accordion>
            }
          </div>
        ))}
    </>
  );
};
