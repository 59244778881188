// Facets found on /search
import React from "react";

import { SearchCardDefault } from "../search-cards/search-cards";

export const SearchResults = ({ results }) => {


  return (
    <>
      {results?.data &&
        results.data.length >= 1 &&
        results.data.map((item) => (
          <div key={item.id}>
            <SearchCardDefault
              fetchNodeUrl={item?.links?.self?.href}
              key={item.id}
            />
          </div>
        ))}
    </>
  );
};
